import React from 'react'
import Header from '../components/Header';
import HeroHomepage from '../components/HeroHomepage';
import ImageContainerHomepage from '../components/ImageContainerHomepage';
import WhoAreWeHomepage from '../components/WhoAreWeHomepage';
import Footer from '../components/Footer';
import { useEffect } from 'react';


const Home = () => {
    useEffect(() => {
        document.title = 'Red Raider Racing';
      }, []);
  return (
    <div>
        <Header />
        <HeroHomepage/>
        <ImageContainerHomepage/>
        <WhoAreWeHomepage/>
        <Footer/>
    </div>
  )
}

export default Home
