import React, { useState } from 'react';
import ServerComponent from './ServerComponent'; // Adjust the path as necessary

const AboutHistory = () => {
  const [configData, setConfigData] = useState(null);

  return (
    <div className="containerr">
      <h2 className="text-center">About <span className="text-primary">Competition</span></h2>
      <ServerComponent setConfigData={setConfigData} />

      {configData && configData.History ? (
        <p className="text-center lg:px-[8vw]">{configData.History.CompetitonText}</p>
      ) : (
        <p>Loading...</p> 
      )}
    </div>
  );
}

export default AboutHistory;



