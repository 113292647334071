import React, { useState } from 'react';
import ServerComponent from './ServerComponent'; // Adjust the path as necessary

const HeroTeam = () => {
  const [configData, setConfigData] = useState(null);

  return (
    <div>
           <ServerComponent setConfigData={setConfigData} />

{configData && configData.Team ? (
      <div className='relative'>
        <div className='picture-overlay w-full absolute h-full inset-0'>

     <img className='absolute object-cover w-full h-full' src={configData.Team.HeroImage} alt='Background'></img>

    
          <div className='gradient-overlay absolute inset-0' ></div>
        </div>
        <div className='relative w-screen overflow-x-hidden px-[5vw] md:px-[10vw] py-[35vh] sm:py-[30vh]'>
          <div className='HeroText text-center w-full'>
            <h1 className='text-[white]  pb-[0.2em] '>OUR <span className='text-primary'>TEAM</span></h1>
            <a className='btn-solid' target="_blank" href={configData.Team.HeroBtnUrl}>{configData.Team.HeroBtnText}</a>
          </div>
        </div>
      </div>    
) : (
  <p>Loading...</p> 
)}
    </div>
  )
}

export default HeroTeam
