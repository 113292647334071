import React, { useState } from 'react';
import ServerComponent from './ServerComponent'; // Adjust the path as necessary

const SponsorsList = () => {
    const [configData, setConfigData] = useState(null);

  return (
    <div className='containerr'>
      <h2 className="text-center">Our <span className="text-primary">Proud </span>Sponsors</h2>

      <div className='grid grid-cols-3 sm:grid-cols-3 lg:grid-cols-4 gap-[2vw]'>
      <ServerComponent setConfigData={setConfigData} />

{configData && configData.Sponsors.SponsorList ? (
  <>{configData.Sponsors.SponsorList.map((sponsor, index) => (
    <a href={sponsor.link} target="_blank" key={index} className='sponsordiv  px-[10px] py-[10px] sm:py-[15px] rounded-[4px] sm:rounded-[13px]'><img src={sponsor.image} className='w-full h-full object-contain' alt='sponsor'></img></a>
  ))}</>
) : (
  <p>Loading...</p> 
)}
            
      </div>
    </div>
  )
}

export default SponsorsList
