import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Header from '../components/Header.js'

function AdminDashboard() {
  useEffect(() => {
    document.title = 'Dashboard - Red Raider Racing';
  }, []);
  const [data, setData] = useState({
    Home: {
      HeroText: "",
      HeroBtnText:"",
      HeroBtnUrl:"",
      HeroImage: "",
      LatestCarTitle: "",
      LatestCarText: "",
      LatestCarImage: "",
      WhoAreWeText: "",
      WhoAreWeImage: ""
    },
    Team: {
      HeroImage: "",
      HeroBtnText:"",
      HeroBtnUrl:"",
      WhoAreWeText: "",
      WhoAreWeImage: "",
      TeamMembers: {
        "Drive Train": [],
        "Electrical": [],
        "Frame, Body, and Aero": [],
        "Vehicle Dynamics": []
      }
    },
    History: {
      HeroImage: "",
      CompetitonText:"",
      Cars: [], // Initialize Cars as an empty array
    },
    Events: {
      HeroImage: "",
      HeroBtnText:"",
      HeroBtnUrl:"",
      CompetitonText:"",
      UpcomingEvents: [], // Initialize Cars as an empty array
      WeeklyEvents: [],
    },
    Contact: {
      HeroImage: "",
      
    },
    Sponsors: {
    ThankYouText: "",
    HeroImage: "",
    HeroBtnText:"",
      HeroBtnUrl:"",
    DonateLink: "",
    SponsorPacket: "",
    SponsorList: [],
  },
  });
  const [loading, setLoading] = useState(true);

  // Fetch data from GitHub gist
  useEffect(() => {
    async function fetchData() {
      try {
        const response = await axios.get('https://api.github.com/gists/b29cb2e7db3509cccd3050adbdc40c3b');
        // Set a default structure in case data is null or undefined
        setData(response.data?.files?.['content.json']?.content ? JSON.parse(response.data.files['content.json'].content) : {
          Home: {
            HeroText: "",
            HeroImage: "",
            HeroBtnText:"",
            HeroBtnUrl:"",
            LatestCarTitle: "",
            LatestCarText: "",
            LatestCarImage: "",
            WhoAreWeText: "",
            WhoAreWeImage: ""
          },
          Team: {
            HeroImage: "",
            HeroBtnText:"",
            HeroBtnUrl:"",
            WhoAreWeText: "",
            WhoAreWeImage: "",
            TeamMembers: {
              "Drive Train": [],
              "Electrical": [],
              "Frame, Body, and Aero": [],
              "Vehicle Dynamics": []
            }
          },
          History: {
            HeroImage: "",
            HeroBtnText:"",
            HeroBtnUrl:"",
            CompetitonText:"",
            Cars: [], // Initialize Cars as an empty array
          },
          Events: {
            HeroImage: "",
            HeroBtnText:"",
            HeroBtnUrl:"",
            CompetitonText:"",
            UpcomingEvents: Array.isArray(response.data?.Events?.UpcomingEvents) ? response.data.Events.UpcomingEvents : [], // Ensure array
            WeeklyEvents: [],
          },
          Contact: {
            HeroImage: ""
          },
          Sponsors: {
            ThankYouText: "",
            HeroImage: "",
            HeroBtnText:"",
            HeroBtnUrl:"",
            DonateLink: "",
            SponsorPacket: "",
            SponsorList: [],
          },
        });
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
      
    }
    fetchData();
  
  }, []);

  console.log(data)
  // Handle text field change
  const handleTextChange = (section, key, value) => {
    setData((prevData) => ({
      ...prevData,
      [section]: {
        ...prevData[section],
        [key]: value,
      },
    }));
  };

  const handleFileUpload = async (section, key, file, subteam = null, memberIndex = null, carIndex = null, eventIndex = null, weeklyEventIndex = null,  sponsorIndex = null) => {
    const formData = new FormData();
    formData.append('file', file);
    formData.append('upload_preset', 'my_upload_preset');
  
    try {
      const response = await axios.post(
        'https://api.cloudinary.com/v1_1/duclkqnbr/image/upload',
        formData
      );
      const imageUrl = response.data.secure_url;
  
      setData((prevData) => {
        // Update image for team members
        if (section === 'Team' && key === 'TeamMembers' && subteam && memberIndex !== null) {
          const updatedMembers = [...prevData.Team.TeamMembers[subteam]];
          updatedMembers[memberIndex] = {
            ...updatedMembers[memberIndex],
            image: imageUrl,
          };
  
          return {
            ...prevData,
            Team: {
              ...prevData.Team,
              TeamMembers: {
                ...prevData.Team.TeamMembers,
                [subteam]: updatedMembers,
              },
            },
          };
        }
  
        // Update image for individual car
        if (section === 'History' && key === 'Cars' && carIndex !== null) {
          const updatedCars = [...prevData.History.Cars];
          updatedCars[carIndex] = {
            ...updatedCars[carIndex],
            image: imageUrl,
          };
  
          return {
            ...prevData,
            History: {
              ...prevData.History,
              Cars: updatedCars,
            },
          };
        }
        if (section === 'Sponsors' && key === 'SponsorList' && sponsorIndex !== null) {
          const updatedSponsors = [...prevData.Sponsors.SponsorList];
          updatedSponsors[sponsorIndex] = {
            ...updatedSponsors[sponsorIndex],
            image: imageUrl,
          };
  
          return {
            ...prevData,
            Sponsors: {
              ...prevData.Sponsors,
              SponsorList: updatedSponsors,
            },
          };
        }
  
        // Update image for Events (UpcomingEvents or WeeklyEvents)
        if (section === 'Events' && (key === 'UpcomingEvents' || key === 'WeeklyEvents')) {
          // Determine the correct index to use
          const eventIndexToUpdate = key === 'UpcomingEvents' ? eventIndex : weeklyEventIndex;
  
          if (eventIndexToUpdate !== null) {
            const updatedEvents = [...prevData.Events[key]];  // Dynamically select the correct array
            updatedEvents[eventIndexToUpdate] = {
              ...updatedEvents[eventIndexToUpdate],
              image: imageUrl,
            };
  
            return {
              ...prevData,
              Events: {
                ...prevData.Events,
                [key]: updatedEvents,  // Dynamically update the correct array
              },
            };
          }
        }
  
        // For regular image updates (e.g., Home or Team Hero Images)
        return {
          ...prevData,
          [section]: {
            ...prevData[section],
            [key]: imageUrl,
          },
        };
      });
    } catch (error) {
      console.error("Error uploading image:", error);
    }
  };
  
  const handleFileUploadd = async (section, key, file) => {
    const formData = new FormData();
    formData.append('file', file);
    formData.append('upload_preset', 'my_upload_preset'); // Ensure this is correctly configured
    formData.append('resource_type', 'raw'); // Specify raw for non-image files
  
    try {
      const response = await axios.post(
        'https://api.cloudinary.com/v1_1/duclkqnbr/raw/upload', // Use /raw/upload for raw files
        formData
      );
      const fileUrl = response.data.secure_url;
  
      setData((prevData) => ({
        ...prevData,
        [section]: {
          ...prevData[section],
          [key]: fileUrl, // Save the file URL
        },
      }));
    } catch (error) {
      console.error('Error uploading file:', error);
    }
  };
  
  
    // Function to handle deleting a car
    const handleDeleteCar = (index) => {
      setData((prev) => ({
        ...prev,
        History: {
          ...prev.History,
          Cars: prev.History.Cars.filter((_, i) => i !== index), // Filter out the car at the specified index
        },
      }));
    };
    const handleDeleteUpcomingEvent = (index) => {
      setData((prev) => ({
        ...prev,
        Events: {
          ...prev.Events,
          UpcomingEvents: prev.Events.UpcomingEvents.filter((_, i) => i !== index), // Filter out the car at the specified index
        },
      }));
    };
    const handleDeleteWeeklyEvent = (index) => {
      setData((prev) => ({
        ...prev,
        Events: {
          ...prev.Events,
          WeeklyEvents: prev.Events.WeeklyEvents.filter((_, i) => i !== index), // Filter out the car at the specified index
        },
      }));
    };
    const handleDeleteFaq = (index) => {
      setData((prev) => ({
        ...prev,
        Contact: {
          ...prev.Events,
          faqs: prev.Contact.faqs.filter((_, i) => i !== index), // Filter out the car at the specified index
        },
      }));
    };
  // Function to handle deleting a team member
  const handleDeleteTeamMember = (subteam, memberIndex) => {
    setData((prev) => ({
      ...prev,
      Team: {
        ...prev.Team,
        TeamMembers: {
          // Correctly update only the specific subteam array
          ...prev.Team.TeamMembers,
          [subteam]: prev.Team.TeamMembers[subteam].filter((_, i) => i !== memberIndex),
        },
      },
    }));
  };
  
  // Handle team member text changes
  const handleTeamMemberChange = (team, index, key, value) => {
    setData((prevData) => {
      const updatedMembers = [...prevData.Team.TeamMembers[team]];
      updatedMembers[index] = {
        ...updatedMembers[index],
        [key]: value,
      };
      return {
        ...prevData,
        Team: {
          ...prevData.Team,
          TeamMembers: {
            ...prevData.Team.TeamMembers,
            [team]: updatedMembers,
          },
        },
      };
    });
  };
 const handleAddMember = (team, e) => {
  e.preventDefault();  // Prevent form submission (page refresh)

  const newMember = {
    name: '',
    role: '',
    linkedin: '',
    email: '',
    image: '',
  };

  // Update state using the callback form of setData to access previous state
  setData((prevData) => {
   

    // Check if the team exists and is an array
    if (!Array.isArray(prevData.Team.TeamMembers[team])) {
      console.error(`No array found for subteam: ${team}`);
      return prevData; // If the team doesn't exist, return the previous state without changes
    }

    // Return the updated state
    return {
      ...prevData,
      Team: {
        ...prevData.Team,
        TeamMembers: {
          ...prevData.Team.TeamMembers,
          [team]: [...prevData.Team.TeamMembers[team], newMember], // Correctly update the team
        },
      },
    };
  });
};

const handleCarChange = (e, index) => {
  const { name, value } = e.target;
  setData((prev) => ({
    ...prev,
    History: {
      ...prev.History,  // Preserve other properties of History
      Cars: prev.History.Cars.map((item, i) =>
        i === index ? { ...item, [name]: value } : item
      ),
    },
  }));
};

const handleAddCar = () => {
  const newCar = {
    model: '',
    year: '',
    chasis: '',
    powerunit: '',
    weight: '',
    achievement: '',
    placement: '',
    image: '',
  };

  setData((prev) => ({
    ...prev,
    History: {
      ...prev.History,  // Preserve other properties of History
      Cars: [newCar, ...prev.History.Cars],  // Add the new car at the start of the array
    },
  }));
};



const handleUpcomingEventChange = (e, index) => {
  const { name, value } = e.target;
  setData((prev) => ({
    ...prev,
    Events: {
      ...prev.Events,
      UpcomingEvents: prev.Events.UpcomingEvents.map((item, i) =>
        i === index ? { ...item, [name]: value } : item
      ),
    },
  }));
};
const handleWeeklyEventChange = (e, index) => {
  const { name, value } = e.target;
  setData((prev) => ({
    ...prev,
    Events: {
      ...prev.Events,
      WeeklyEvents: prev.Events.WeeklyEvents.map((item, i) =>
        i === index ? { ...item, [name]: value } : item
      ),
    },
  }));
};
const handleFaq = (e, index) => {
  const { name, value } = e.target;
  setData((prev) => ({
    ...prev,
    Contact: {
      ...prev.Contact,
      faqs: prev.Contact.faqs.map((item, i) =>
        i === index ? { ...item, [name]: value } : item
      ),
    },
  }));
};


// Add New Event Entry for Upcoming Events
const addUpcomingEvent = () => {
  
  const newUpcomingEvent = {date: '', month: '', title: '', location: '', image: ''};
  setData((prev) => ({
    ...prev,
    Events: {
      ...prev.Events,  // Preserve other properties of History
      UpcomingEvents: [...prev.Events.UpcomingEvents, newUpcomingEvent],
    },
  }));
};


const handleSponsorChange = (e, index) => {
  const { name, value } = e.target;
  setData((prev) => ({
    ...prev,
    Sponsors: {
      ...prev.Sponsors,
      SponsorList: prev.Sponsors.SponsorList.map((item, i) =>
        i === index ? { ...item, [name]: value } : item
      ),
    },
  }));
};
const AddSponsor = () => {
  
  const newSponsor = {image: '', link:''};
  setData((prev) => ({
    ...prev,
    Sponsors: {
      ...prev.Sponsors,  // Preserve other properties of History
      SponsorList: [...prev.Sponsors.SponsorList, newSponsor],
    },
  }));
};
const deleteSponsor = (index) => {
  setData((prev) => ({
    ...prev,
    Sponsors: {
      ...prev.Sponsors,
      SponsorList: prev.Sponsors.SponsorList.filter((_, i) => i !== index), // Filter out the car at the specified index
    },
  }));
};
const addFaq = () => {
  const newFaq = {question:'', answer:''};
  setData((prev) => ({
    ...prev,
    Contact: {
      ...prev.Contact,  // Preserve other properties of History
      faqs: [...prev.Contact.faqs, newFaq],
    },
  }));
};

const addWeeklyEvent = () => {
  const newWeeklyEvent = {day: '', title: '', location: '', image: ''};
  setData((prev) => ({
    ...prev,
    Events: {
      ...prev.Events,  // Preserve other properties of History
      WeeklyEvents: [...prev.Events.WeeklyEvents, newWeeklyEvent],
    },
  }));
};

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const updatedContent = JSON.stringify(data, null, 2); // Ensure it's JSON-formatted
      await axios.patch(
        'https://api.github.com/gists/b29cb2e7db3509cccd3050adbdc40c3b',
        {
          files: {
            'content.json': {  // This should match the filename in your Gist
              content: updatedContent,
            },
          },
        },
        {
          headers: {
            Authorization: `token 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`, // Replace with your GitHub token
            'Content-Type': 'application/json',
          },
        }
      );
      alert('Gist updated successfully!');
    } catch (err) {
      console.error('Error updating Gist data:', err.response ? err.response.data : err.message);
    }
  };

  if (loading) return <div>Loading...</div>;

  return (
    <div>    
      <Header/>
      <div className="admin-dashboard py-[120px] bg-[#fff]">
              <h1 className='text-[100px]' >Admin Dashboard</h1>
              <div className="px-[20%] text-left py-[3em]">
              <p className='text-black'>Please follow these insructions for pictures to keep the website run smoothly:<br/>Always convert your images into webp format before uploading. <br/> Recommended dimensions:</p>
              <ul className='text-[18px]'>
                <li><span className='font-semibold leading-[2em]'>Header Image:</span> 1920 x 1080</li>
                <li><span className='font-semibold leading-[2em]'>Sponsor Image:</span> 250 x 150</li>
                <li><span className='font-semibold leading-[2em]'>Team Member's Image:</span> 150 x 150</li>
                <li><span className='font-semibold leading-[2em]'>Event Image:</span> 300 x 170</li>
                <li><span className='font-semibold leading-[2em]'>Car Image:</span> 700 x 400</li>

              </ul>
              </div>
      <form className='text-black  w-[70%] mx-auto py-[40px] px-[10%] text-left adminDashboardForm'>
        <div>
          <div className='HOMEPAGE'>
            <h2 className='text-center text-[blue]'>Home Section</h2>
            <label>
              Hero Text:<br/>
              <input
                type="text"
                value={data?.Home?.HeroText || ""}
                onChange={(e) => handleTextChange('Home', 'HeroText', e.target.value)}
                className='border-[1px] border-[black]'
              />
            </label>

            {/* IMAGE */}
            <div>
              <label>Hero Image:</label> <br/>
              <img src={data?.Home?.HeroImage || ""} alt="Hero" width="300" className='mx-auto'/>
              <input
                type="file"
                onChange={(e) => handleFileUpload('Home', 'HeroImage', e.target.files[0])}
              />
            </div>
            <label>
              Hero Button Text:<br/>
              <input
                type="text"
                value={data?.Home?.HeroBtnText || ""}
                onChange={(e) => handleTextChange('Home', 'HeroBtnText', e.target.value)}
                className='border-[1px] border-[black]'
              />
            </label>
            <label>
              Hero Button Url:<br/>
              <input
                type="text"
                value={data?.Home?.HeroBtnUrl || ""}
                onChange={(e) => handleTextChange('Home', 'HeroBtnUrl', e.target.value)}
                className='border-[1px] border-[black]'
              />
            </label>
            {/* IMAGE */}
            <label>
              Latest Car Title:<br/>
              <input
                type="text"
                value={data?.Home?.LatestCarTitle || ""}
                onChange={(e) => handleTextChange('Home', 'LatestCarTitle', e.target.value)}
                className='border-[1px] border-[black]'
              />
            </label><br/>
            <label>
              Latest Car Text:<br/>
              <textarea
                type="text"
                value={data?.Home?.LatestCarText || ""}
                onChange={(e) => handleTextChange('Home', 'LatestCarText', e.target.value)}
                className='border-[1px] border-[black] bg-white w-[40%]'  
                rows="7"
              />
            </label>
            <div>
              <label>Latest Car Image:<br/></label> 
              <img src={data?.Home?.LatestCarImage || ""} alt="Latest Car" width="300" className='mx-auto'/>
              <input
                type="file"
                onChange={(e) => handleFileUpload('Home', 'LatestCarImage', e.target.files[0])}
              />
            </div>
            <label>
              Who Are We Text:<br/>
              <textarea
                type="text"
                value={data?.Home?.WhoAreWeText || ""}
                onChange={(e) => handleTextChange('Home', 'WhoAreWeText', e.target.value)}
                className='border-[1px] border-[black] bg-white w-[40%]'  
                rows="7"
              />
            </label>
            <div>
              <label>Who Are We Image:<br/></label> 
              <img src={data?.Home?.WhoAreWeImage || ""} alt="WhoAreWe" width="300" className='mx-auto'/>
              <input
                type="file"
                onChange={(e) => handleFileUpload('Home', 'WhoAreWeImage', e.target.files[0])}
              />
            </div>
            {/* Repeat similar structure for other fields */}
          </div>
          
          <div className="TEAM">

            
            <h2 className='text-[blue] text-center'>Team Section</h2>
            
            {/* Team Hero Image */}
            <div>
              <label>Hero Image:</label>
              <img src={data?.Team?.HeroImage || ""} alt="Team Hero" width="300" className="mx-auto" />
              <input
                type="file"
                onChange={(e) => handleFileUpload('Team', 'HeroImage', e.target.files[0])}
              />
            </div>
            <label>
              Hero Button Text:<br/>
              <input
                type="text"
                value={data?.Team?.HeroBtnText || ""}
                onChange={(e) => handleTextChange('Team', 'HeroBtnText', e.target.value)}
                className='border-[1px] border-[black]'
              />
            </label>
            <label>
              Hero Button Url:<br/>
              <input
                type="text"
                value={data?.Team?.HeroBtnUrl || ""}
                onChange={(e) => handleTextChange('Team', 'HeroBtnUrl', e.target.value)}
                className='border-[1px] border-[black]'
              />
            </label>
            <label>
              Who Are We Text:<br/>
              <textarea
                type="text"
                value={data?.Team?.WhoAreWeText || ""}
                onChange={(e) => handleTextChange('Team', 'WhoAreWeText', e.target.value)}
                className='border-[1px] border-[black] bg-white w-[40%]'  
                rows="7"
              />
            </label>
            <div>
              <label>Who Are We Image:<br/></label>
              <img src={data?.Team?.WhoAreWeImage || ""} alt="Team Hero" width="300" className="mx-auto" />
              <input
                type="file"
                onChange={(e) => handleFileUpload('Team', 'WhoAreWeImage', e.target.files[0])}
              />
            </div>
            <h3 className='text-[red] mt-[1em] text-[30px] font-medium underline' >Team Members:</h3>
            <div>



            
                  
            </div>
            {Object.entries(data.Team.TeamMembers).map(([subteam, members], index) => (
              <div key={subteam}>
                <h4 className='text-[red] mt-[1em] text-[24px]'>{subteam}</h4>

                {members.map((member, i) => (
                  <div key={i} className='arrayElement'>
                    <div>
                      <img src={member.image || ""} alt="Member" width="100" className="mx-auto" />
                      <input
                        type="file"
                        onChange={(e) => handleFileUpload('Team', 'TeamMembers', e.target.files[0], subteam, i)}
                        />
                    </div>
                    <label>Name: <br/>
                    <input
                      className='ml-[0.5em] mt-[0.5em] border-[1px] border-[black]'
                      type="text"
                      value={member.name}
                      onChange={(e) => handleTeamMemberChange(subteam, i, 'name', e.target.value)}
                      placeholder="Name"
                    /></label><br/>
                    <label>Role:<br/>
                    <input
                      className='ml-[0.5em] mt-[0.5em] border-[1px] border-[black]'
                      type="text"
                      value={member.role}
                      onChange={(e) => handleTeamMemberChange(subteam, i, 'role', e.target.value)}
                      placeholder="role"
                    /></label><br/>
                    <label>Email:<br/>
                    <input
                      type="text"
                      value={member.email}
                      onChange={(e) => handleTeamMemberChange(subteam, i, 'email', e.target.value)}
                      placeholder="Email"
                      className='ml-[0.5em] mt-[0.5em] border-[1px] border-[black]'
                    /></label><br/>
                    <label>Linkedin: <br/>
                    <input
                      className='ml-[0.5em] mt-[0.5em] border-[1px] border-[black]'
                      type="text"
                      value={member.linkedin}
                      onChange={(e) => handleTeamMemberChange(subteam, i, 'linkedin', e.target.value)}
                      placeholder="linkedin"
                    /></label><br/>
                    {/* Image upload for each team member */}
                    <button type="button" onClick={() => handleDeleteTeamMember(subteam, i)} className="delete-button">
                    Delete Team Member
                  </button>

                  </div>
                ))}

              <button onClick={(e) => handleAddMember(subteam, e)} className='add-button'>Add Member</button>

              </div>
            ))}
          </div>

          <div className='HISTORY'>
            <h2 className='text-center text-[blue]'>History Section</h2>

            <div>
              <label>Hero Image:</label> <br/>
              <img src={data?.History?.HeroImage || ""} alt="Hero" width="300" className='mx-auto'/>
              <input
                type="file"
                onChange={(e) => handleFileUpload('History', 'HeroImage', e.target.files[0])}
              />
            </div>
            <label>
              Hero Button Text:<br/>
              <input
                type="text"
                value={data?.History?.HeroBtnText || ""}
                onChange={(e) => handleTextChange('History', 'HeroBtnText', e.target.value)}
                className='border-[1px] border-[black]'
              />
            </label>
            <label>
              Hero Button Url:<br/>
              <input
                type="text"
                value={data?.History?.HeroBtnUrl || ""}
                onChange={(e) => handleTextChange('History', 'HeroBtnUrl', e.target.value)}
                className='border-[1px] border-[black]'
              />
            </label>
            <label>
              Competiton Text:<br/>
              <textarea
                type="text"
                value={data?.History?.CompetitonText || ""}
                onChange={(e) => handleTextChange('History', 'CompetitonText', e.target.value)}
                className='border-[1px] border-[black] bg-white w-[40%]'  
                rows="7"
              />
            </label>
          <h3 className='text-[30px] font-[500] text-[black] text-center'>Cars</h3>

         {data.History.Cars.map((item, index) => (
          <div key={index} className="car-entry arrayElement" >
            <label>
              Model:<br/>
              <input
                type="text"
                name="model"
                value={item.model}
                onChange={(e) => handleCarChange(e, index)}
                required
                className='border-[1px] border-[#ddd]'
              />
            </label><br/>
            <label>
              Year:<br/>
              <input
                type="text"
                name="year"
                value={item.year}
                onChange={(e) => handleCarChange(e, index)}
                required
                className='border-[1px] border-[#ddd]'

              />
            </label><br/>
            <label>
              Chasis:<br/>
              <input
                type="text"
                name="chasis"
                value={item.chasis}
                onChange={(e) => handleCarChange(e, index)}
                required
                className='border-[1px] border-[#ddd]'

              />
            </label><br/>
            <label>
              Power Unit:<br/>
              <input
                type="text"
                name="powerunit"
                value={item.powerunit}
                onChange={(e) => handleCarChange(e, index)}
                required
                className='border-[1px] border-[#ddd]'

              />
            </label><br/>
            <label>
              Weight:<br/>
              <input
                type="text"
                name="weight"
                value={item.weight}
                onChange={(e) => handleCarChange(e, index)}
                required
                className='border-[1px] border-[#ddd]'

              />
            </label><br/>
            <label>
              Achievement:<br/>
              <input
                type="text"
                name="achievement"
                value={item.achievement}
                onChange={(e) => handleCarChange(e, index)}
                required
                className='border-[1px] border-[#ddd]'

              />
            </label><br/>
            <label>
              Placement:<br/>
              <input
                type="text"
                name="placement"
                value={item.placement}
                onChange={(e) => handleCarChange(e, index)}
                required
                className='border-[1px] border-[#ddd]'

              />
            </label><br/>
            <label>
              Image:<br/>
              <input
                type="file"
                accept="image/*"
                onChange={(e) => handleFileUpload('History', 'Cars', e.target.files[0], null, null, index)} // Pass the index here
                />
            </label>
            {item.image && (
              <div>
                <p>Uploaded Image:</p>
                <img src={item.image} alt="Car" className="w-[300px] mx-auto " />
              </div>
            )}
            <button type="button" onClick={() => handleDeleteCar(index)} className="delete-button">
                  Delete Car
                </button>
          </div>
        ))}
         <button type="button" onClick={handleAddCar} className='add-button'>
            Add Car
         </button>
          </div>

          <div className='EVENTS'>
          <h2 className='text-[blue] text-center'>Events Section</h2>
          <div>
              <label>Hero Image:</label> <br/>
              <img src={data?.Events?.HeroImage || ""} alt="Hero" width="300" className='mx-auto'/>
              <input
                type="file"
                onChange={(e) => handleFileUpload('Events', 'HeroImage', e.target.files[0])}
              />
            </div>
            <label>
              Hero Button Text:<br/>
              <input
                type="text"
                value={data?.Events?.HeroBtnText || ""}
                onChange={(e) => handleTextChange('Events', 'HeroBtnText', e.target.value)}
                className='border-[1px] border-[black]'
              />
            </label>
            <label>
              Hero Button Url:<br/>
              <input
                type="text"
                value={data?.Events?.HeroBtnUrl || ""}
                onChange={(e) => handleTextChange('Events', 'HeroBtnUrl', e.target.value)}
                className='border-[1px] border-[black]'
              />
            </label>
<div>
          <h3 className='text-[30px] font-[500] text-[black] text-center'>Upcoming Events</h3>
      {data.Events.UpcomingEvents.map((event, index) => (


        <div key={index} className='upcoming-event arrayElement'>
          <label> Date<br/>
          <input
            type="text"
            name="date"
            placeholder="Date"
            value={event.date}
            className='border-[1px] border-[#ddd]'
            onChange={(e) => handleUpcomingEventChange(e, index)}
          /></label><br/>
          <label>Month<br/>
          <input
            type="text"
            name="month"
            placeholder="Month"
            value={event.month}
            className='border-[1px] border-[#ddd]'
            onChange={(e) => handleUpcomingEventChange(e, index)}
          /></label><br/>
          <label>Title<br/>
          <input
            type="text"
            name='title'
            placeholder="Title"
            value={event.title}
            className='border-[1px] border-[#ddd]'
            onChange={(e) => handleUpcomingEventChange(e, index)}
          /></label><br/>
          <label>Location<br/>
          <input
            type="text"
            name="location"
            placeholder="Location"
            value={event.location}
            className='border-[1px] border-[#ddd]'
            onChange={(e) => handleUpcomingEventChange(e, index)}
            /></label><br/>
          <label>
              Image:<br/>
              <input
                type="file"
                accept="image/*"
                onChange={(e) => handleFileUpload('Events', 'UpcomingEvents', e.target.files[0], null, null, null, index)} // Pass the index here
                />
            </label>
            {event.image && (
              <div>
                <p>Uploaded Image:</p>
                <img src={event.image} alt="Car" className="w-[300px] mx-auto " />
              </div>
            )}
        <button type="button" onClick={() => handleDeleteUpcomingEvent(index)} className="delete-button">Delete Upcoming Event</button>
        </div>
      ))}
      <button type="button" onClick={addUpcomingEvent} className='add-button'>Add Upcoming Event</button>



      </div>

      <div>
      <h3 className='text-[30px] font-[500] text-[black] text-center'>Weekly Events</h3>
      {data.Events.WeeklyEvents.map((event, index) => (


        <div key={index} className='weekly-event arrayElement'>
          <label> Day<br/>
          <input
            type="text"
            name="day"
            placeholder="day"
            value={event.day}
            className='border-[1px] border-[#ddd]'
            onChange={(e) => handleWeeklyEventChange(e, index)}
          /></label><br/>
          <label>Title:<br/>
          <input
            type="text"
            name='title'
            placeholder="Title"
            value={event.title}
            className='border-[1px] border-[#ddd]'
            onChange={(e) => handleWeeklyEventChange(e, index)}
          /></label><br/>
          <label>Location:<br/>
          <input
            type="text"
            name="location"
            placeholder="Location"
            value={event.location}
            className='border-[1px] border-[#ddd]'
            onChange={(e) => handleWeeklyEventChange(e, index)}
            /></label><br/>
          <label>
              Image:<br/>
              <input
                type="file"
                accept="image/*"
                onChange={(e) => handleFileUpload('Events', 'WeeklyEvents', e.target.files[0], null, null, null, null, index)} // Pass the index here
                />
            </label>
            {event.image && (
              <div>
                <p>Uploaded Image:</p>
                <img src={event.image} alt="Car" className="w-[300px] mx-auto " />
              </div>
            )}
            <button type="button" onClick={() => handleDeleteWeeklyEvent(index)} className="delete-button">Delete Weekly Event</button>

        </div>
      ))}
      <button type="button" onClick={addWeeklyEvent} className='add-button'>Add Weekly Event</button>



      </div>
      
      
      </div>
      <div className='CONTACT'>
            <h2 className='text-center text-[blue]'>Contact Page</h2>

            {/* IMAGE */}
            <div>
              <label>Hero Image:<br/></label> 
              <img src={data?.Contact?.HeroImage || ""} alt="Hero" width="300" className='mx-auto'/>
              <input
                type="file"
                onChange={(e) => handleFileUpload('Contact', 'HeroImage', e.target.files[0])}
              />
            </div>
           
            <div>
      <h3 className='text-[30px] font-[500] text-[black] text-center mt-[0.5em]'>FAQs</h3>

      {data.Contact.faqs.map((faq, index) => (
        <div key={index} className='weekly-event arrayElement'>
          <label>Question<br/>
          <input
            type="text"
            name="question"
            placeholder="Question"
            value={faq.question}
            className='border-[1px] border-[#ddd]'
            onChange={(e) => handleFaq(e, index)}
          /></label><br/>
          <label>Answer<br/>
          <input
            type="text"
            name='answer'
            placeholder="Answer"
            value={faq.answer}
            className='border-[1px] border-[#ddd]'
            onChange={(e) => handleFaq(e, index)}
          /></label><br/>
            <button type="button" onClick={() => handleDeleteFaq(index)} className="delete-button">Delete FAQ</button>

        </div>
      ))}
      <button type="button" onClick={addFaq} className='add-button'>Add FAQ</button>



      </div>
            
            
            {/* Repeat similar structure for other fields */}
          </div>



          <div className='SPONSORS'>
            <h2 className='text-center text-[blue]'>Sponsors Page</h2>

            {/* IMAGE */}
            <div>
              <label>Hero Image:<br/></label> 
              <img src={data?.Sponsors?.HeroImage || ""} alt="Hero" width="300" className='mx-auto'/>
              <input
                type="file"
                onChange={(e) => handleFileUpload('Sponsors', 'HeroImage', e.target.files[0])}
              />
            </div>
            <label>
              Hero Button Text:<br/>
              <input
                type="text"
                value={data?.Sponsors?.HeroBtnText || ""}
                onChange={(e) => handleTextChange('Sponsors', 'HeroBtnText', e.target.value)}
                className='border-[1px] border-[black]'
              />
            </label>
            <label>
              Hero Button Url:<br/>
              <input
                type="text"
                value={data?.Sponsors?.HeroBtnUrl || ""}
                onChange={(e) => handleTextChange('Sponsors', 'HeroBtnUrl', e.target.value)}
                className='border-[1px] border-[black]'
              />
            </label>
            <label>
              Thank You Text:<br/>
              <textarea
                type="text"
                value={data?.Sponsors?.ThankYouText || ""}
                onChange={(e) => handleTextChange('Sponsors', 'ThankYouText', e.target.value)}
                className='border-[1px] border-[black] bg-white w-[40%]'  
                rows="7"
              />
            </label><br/>
            <label>
              Why Sponsor Us Text:<br/>
              <textarea
                type="text"
                value={data?.Sponsors?.WhySponsorText || ""}
                onChange={(e) => handleTextChange('Sponsors', 'WhySponsorText', e.target.value)}
                className='border-[1px] border-[black] bg-white w-[40%]'  
                rows="7"
              />
            </label><br/>
            <label>
              Donate Link:<br/>
              <input
                type="text"
                value={data?.Sponsors?.DonateLink || ""}
                onChange={(e) => handleTextChange('Sponsors', 'DonateLink', e.target.value)}
                className='border-[1px] border-[black]'
              />
            </label><br/>
            <div>
              <label>Sponsor Packet:<br/></label> 
              <input
                type="file"
                onChange={(e) => handleFileUploadd('Sponsors', 'SponsorPacket', e.target.files[0])}
              />
            </div>
            <div>
      <h3 className='text-[30px] font-[500] text-[black] text-center mt-[0.5em]'>Sponsors List</h3>

      {data.Sponsors.SponsorList.map((sponsor, index) => (
        <div key={index} className='sponsors arrayElement'>
          <label>Link<br/>
          <input
            type="text"
            name="link"
            placeholder="Link"
            value={sponsor.link}
            className='border-[1px] border-[#ddd]'
            onChange={(e) => handleSponsorChange(e, index)}
          /></label><br/>
          <label>
              Image:<br/>
              <input
                type="file"
                accept="image/*"
                onChange={(e) => handleFileUpload('Sponsors', 'SponsorList', e.target.files[0], null, null, null, null, null, index)} // Pass the index here
                />
          </label>
          {sponsor.image && (
              <div>
                <p>Uploaded Image:</p>
                <img src={sponsor.image} alt="Sponsor" className="w-[300px] mx-auto " />
              </div>
            )}
            
            <button type="button" onClick={() => deleteSponsor(index)} className="delete-button">Delete Sponsor</button>

        </div>
      ))}
      <button type="button" onClick={AddSponsor} className='add-button'>Add Sponsor</button>



      </div>
            
            
            {/* Repeat similar structure for other fields */}
          </div>
        </div>

        <button type="submit" onClick={handleSubmit} className=' mt-[1em] px-[1em] py-[0.3em] text-white bg-[red] text-white text-[30px] font-semibold rounded-[15px]'>Submit</button>
      </form>
    </div></div>

  );
}

export default AdminDashboard;
