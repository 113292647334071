import React from 'react'
import Header from '../components/Header';
import Footer from '../components/Footer';
import HeroSponsors from '../components/HeroSponsors';
import ThankYouSponsors from '../components/ThankYouSponsors';
import SponsorsText from '../components/SponsorsText';
import { useEffect } from 'react';
import SponsorsList from '../components/SponsorsList';
const Sponsors = () => {
  useEffect(() => {
    document.title = 'Sponsors | Red Raider Racing';
  }, []);
  return (
    <div>
      <Header/>
      <HeroSponsors/>
      <SponsorsText/>
      <SponsorsList/>
      <ThankYouSponsors/>
      <Footer/>
    </div>
  )
}

export default Sponsors
