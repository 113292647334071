import React, { useState } from 'react';
import ServerComponent from './ServerComponent'; // Adjust the path as necessary

const WhatWeDoTeam = () => {
  const [configData, setConfigData] = useState(null);

  return (
<div className='containerr'>
    <h2 className='text-center'><span className='text-primary'>Who</span> Are We?</h2>
    <div className='sm:flex gap-[5vw]'>
    <ServerComponent setConfigData={setConfigData} />

    {configData && configData.Contact ? (
      <>
      <div className='sm:w-1/2 flexCol'>
                  <p className='pb-[2em] sm:pb-0'>{configData.Team.WhoAreWeText}</p>
                </div>
                <div className='sm:w-1/2'>
                  <img className='w-full h-full object-cover bg-[#D9D9D9] aspect-[5/4] object-top' src={configData.Team.WhoAreWeImage}  alt="sample-pic"></img>
                </div>
      </>
    ) : (
    <p>Loading...</p> 
    )}
            
        </div>
</div>
  )
}

export default WhatWeDoTeam
