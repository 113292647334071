import React from 'react'

const Footer = () => {
  return (
    <div className=' px-[10vw] sm:px-[10vw] py-[30px]'>
           <hr 
        className='mx-auto w-4/5 mt-[50px] sm:mt-[80px] h-[1px] border-[0] relative' 
        style={{
          background: `linear-gradient(
            to right, 
            rgba(225, 225, 225, 0) 0%, 
            #7A7A7A 25%, 
            #7A7A7A 75%, 
            rgba(187, 187, 187, 0) 100%
          )`
        }} 
      ></hr>
      <div className='sm:flex justify-between text-[white] py-[70px]'>
        <div className='pb-[40px]'>
          <img src='./images/logo-white.jpeg' className='h-[60px] sm:h-[70px] lg:h-[80px]' alt="Logo"></img>
        </div>
        <div className='flex justify-between sm:justify gap-[5vw] text-[16px]  lg:text-[20px] '>
        <div className=''>
          <ul className='text-left leading-[2em] '>
            <li className=''><a href='/PrivacyPolicy'>Privacy Policy</a></li>
            <li className=''><a href='/Terms'>Terms Of Service</a></li>
          </ul>
        </div>
        <div className=''>
                  <a className='' href='https://www.facebook.com/RedRaiderRacing/'>
        <svg xmlns="http://www.w3.org/2000/svg" className=" w-[48px] lg:w-[52px]" viewBox="0 0 58 58">
<path d="M32,6C17.642,6,6,17.642,6,32c0,13.035,9.603,23.799,22.113,25.679V38.89H21.68v-6.834h6.433v-4.548	c0-7.529,3.668-10.833,9.926-10.833c2.996,0,4.583,0.223,5.332,0.323v5.965h-4.268c-2.656,0-3.584,2.52-3.584,5.358v3.735h7.785	l-1.055,6.834h-6.73v18.843C48.209,56.013,58,45.163,58,32C58,17.642,46.359,6,32,6z" fill="white"></path>
</svg></a>

<a className='' href='https://www.instagram.com/redraiderracing/'>
<svg className=" pt-[1em] w-[48px] lg:w-[52px]" viewBox="0 0 55 55" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fillRule="evenodd" clipRule="evenodd" d="M27.5 41.999C35.7843 41.999 42.5 35.3624 42.5 27.1758C42.5 18.9891 35.7843 12.3526 27.5 12.3526C19.2157 12.3526 12.5 18.9891 12.5 27.1758C12.5 35.3624 19.2157 41.999 27.5 41.999ZM27.5 37.0579C33.0228 37.0579 37.5 32.6334 37.5 27.1758C37.5 21.718 33.0228 17.2936 27.5 17.2936C21.9771 17.2936 17.5 21.718 17.5 27.1758C17.5 32.6334 21.9771 37.0579 27.5 37.0579Z" fill="white"/>
<path d="M42.5 9.88211C41.1192 9.88211 40 10.9882 40 12.3526C40 13.7171 41.1192 14.8232 42.5 14.8232C43.8807 14.8232 45 13.7171 45 12.3526C45 10.9882 43.8807 9.88211 42.5 9.88211Z" fill="white"/>
<path fillRule="evenodd" clipRule="evenodd" d="M1.6349 8.09361C0 11.2645 0 15.4154 0 23.7171V30.6346C0 38.9363 0 43.0873 1.6349 46.258C3.073 49.0472 5.3677 51.3149 8.19015 52.736C11.3988 54.3517 15.5992 54.3517 24 54.3517H31C39.4007 54.3517 43.6012 54.3517 46.8097 52.736C49.6322 51.3149 51.927 49.0472 53.365 46.258C55 43.0873 55 38.9363 55 30.6346V23.7171C55 15.4154 55 11.2645 53.365 8.09361C51.927 5.30443 49.6322 3.03678 46.8097 1.61563C43.6012 0 39.4007 0 31 0H24C15.5992 0 11.3988 0 8.19015 1.61563C5.3677 3.03678 3.073 5.30443 1.6349 8.09361ZM31 4.94107H24C19.7171 4.94107 16.8056 4.94492 14.5552 5.1266C12.3631 5.30359 11.2421 5.62439 10.4601 6.01814C8.57847 6.96559 7.04868 8.47736 6.08993 10.3368C5.69148 11.1096 5.36685 12.2174 5.18775 14.3836C5.0039 16.6075 5 19.4847 5 23.7171V30.6346C5 34.8671 5.0039 37.7441 5.18775 39.968C5.36685 42.1344 5.69148 43.2422 6.08993 44.015C7.04868 45.8743 8.57847 47.3861 10.4601 48.3335C11.2421 48.7273 12.3631 49.0482 14.5552 49.2251C16.8056 49.4067 19.7171 49.4107 24 49.4107H31C35.283 49.4107 38.1943 49.4067 40.4448 49.2251C42.637 49.0482 43.758 48.7273 44.54 48.3335C46.4215 47.3861 47.9512 45.8743 48.91 44.015C49.3085 43.2422 49.6333 42.1344 49.8122 39.968C49.996 37.7441 50 34.8671 50 30.6346V23.7171C50 19.4847 49.996 16.6075 49.8122 14.3836C49.6333 12.2174 49.3085 11.1096 48.91 10.3368C47.9512 8.47736 46.4215 6.96559 44.54 6.01814C43.758 5.62439 42.637 5.30359 40.4448 5.1266C38.1943 4.94492 35.283 4.94107 31 4.94107Z" fill="white"/>
</svg>

        </a>
        </div>

      </div>
      </div>
   
      <p className='text-[12px]  lg:text-[18px] text-[grey] text-center pt-[1em] font-light'>© {new Date().getFullYear()} Red Raider Racing Formula SAE Team. All rights reserved.</p>

      <p className='text-center text-[16px] sm:text-[20px]  lg:text-[22px] mt-[1em] leading-[2em] pb-[1em]'>Crafted with <span><svg viewBox="0 0 24 24"  className="inline-block h-[1.5em]" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" strokeWidth="0"></g><g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g><g id="SVGRepo_iconCarrier"> <path fillRule="evenodd" clipRule="evenodd" d="M12 6.00019C10.2006 3.90317 7.19377 3.2551 4.93923 5.17534C2.68468 7.09558 2.36727 10.3061 4.13778 12.5772C5.60984 14.4654 10.0648 18.4479 11.5249 19.7369C11.6882 19.8811 11.7699 19.9532 11.8652 19.9815C11.9483 20.0062 12.0393 20.0062 12.1225 19.9815C12.2178 19.9532 12.2994 19.8811 12.4628 19.7369C13.9229 18.4479 18.3778 14.4654 19.8499 12.5772C21.6204 10.3061 21.3417 7.07538 19.0484 5.17534C16.7551 3.2753 13.7994 3.90317 12 6.00019Z" stroke="#fff" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"></path> </g></svg></span> at <a className='border-b-[0.5px] border-[white]' href='https://thenoodleworks.com/'>The NoodleWorks.</a> </p>
    </div>
  )
}

export default Footer
