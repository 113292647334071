import React from 'react'
import Header from '../components/Header'
import Footer from '../components/Footer'
const PrivacyPolicy = () => {
  return (
    <div>
      <Header/>
      <div className="containerr">
        <h2 className='py-[3em] text-center'><span className='text-primary'>Privacy</span> Policy</h2><br/>
        <p className=''>Last updated: October 2nd, 2023</p> 
        <p className='py-[1em]'>Thank you for visiting Red Raider Racing operated by Red Raider Racing. We value and respect your privacy and are committed to protecting your personal information. This Privacy Policy outlines how we collect, use, disclose, and safeguard your data when you use our website.</p>
        <div className='px-[5%]'>
        <p className='pt-[1em]'><span className='pb-[0.7em] font-semibold'> Information Collected:
        </span><br/> We may collect personally identifiable information, including but not limited to names, email addresses, and contact information when voluntarily provided by you through forms or other means on our website.</p>

        <p className='pt-[1em]'><span className='pb-[0.7em] font-semibold'> Use of Information:
        </span><br/> We use the collected information to respond to your inquiries, provide requested services, improve our website, and communicate with you regarding updates and promotions.</p>

        <p className='pt-[1em]'><span className='pb-[0.7em] font-semibold'>Disclosure of Information:
        </span><br/> We do not sell or disclose your personal information to third parties without your explicit consent, except as required by law or as necessary to provide services.</p>

        <p className='pt-[1em]'><span className='pb-[0.7em] font-semibold'>Cookies and Tracking Technologies:
        </span><br/>Our website may use cookies and similar tracking technologies to enhance your experience. You can control cookies through your browser settings.</p>

        <p className='pt-[1em]'><span className='pb-[0.7em] font-semibold'> Changes to Terms
        </span><br/>We implement reasonable security measures to safeguard your information against unauthorized access and misuse.</p>

        <p className='pt-[1em]'><span className='pb-[0.7em] font-semibold'>Security:
        </span><br/>We may terminate or suspend your access to the Website immediately, without prior notice or liability, for any reason whatsoever.</p>

        <p className='pt-[1em]'><span className='pb-[0.7em] font-semibold'>Third-Party Links:
        </span><br/>Our website may contain links to third-party websites. We are not responsible for the privacy practices or content of these third-party sites.</p>

        <p className='pt-[1em]'><span className='pb-[0.7em] font-semibold'>Children's Privacy:
        </span><br/>Our website is not intended for use by individuals under the age of 13. We do not knowingly collect information from children.</p>

        <p className='pt-[1em]'><span className='pb-[0.7em] font-semibold'>Changes to this Privacy Policy:
        </span><br/>We reserve the right to update or modify this Privacy Policy at any time. Changes will be effective immediately upon posting on the website.</p>

        </div>
       
        <p className='pt-[1em]'>By using our website, you consent to the terms of this Privacy Policy.</p>
        <p className='pt-[1em]'>If you have any questions or concerns about this Privacy Policy, please <a href='/Contact' className='text-primary'>Contact Us</a>.</p>
      </div>
      <Footer/>
    </div>
  )
}

export default PrivacyPolicy
