import React, { useState } from 'react';
import ServerComponent from './ServerComponent'; // Adjust 

const ThankYouSponsors = () => {
  const [configData, setConfigData] = useState(null);

  return (
<div className='containerr'>
      
      <ServerComponent setConfigData={setConfigData} />

{configData && configData.Sponsors ? (
  <>
        <h2 className='text-center pb-[0.5em]'>A <span className='text-primary'> Thank You</span> note</h2>
        <p className='text-center lg:px-[10vw]'>{configData.Sponsors.ThankYouText}</p>
       
        
</>
) : (
<p>Loading...</p> 
)}

 
    </div>
  )
}

export default ThankYouSponsors
