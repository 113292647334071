import React from 'react'
import Header from '../components/Header'
import Footer from '../components/Footer'
const Terms = () => {
  return (
    <div>
      <Header/>
      <div className="containerr">
        <h2 className='py-[3em] text-center'>Terms Of <span className='text-primary'>Service</span></h2><br/>
        <p className=''>Last updated: October 2nd, 2023</p> 
        <p className='py-[1em]'>These Terms of Service ("Terms") govern your use of Red Raider Racing (the "Website") operated by Red Raider Racing ("us," "we," "our").</p>
        <p>Please read these Terms carefully before using our Website.</p>
        <div className='px-[5%]'>
        <p className='pt-[1em]'><span className='pb-[0.7em] font-semibold'> Acceptance of Terms
        </span><br/> By accessing or using our Website, you agree to be bound by these Terms and all applicable laws and regulations. If you disagree with any part of these Terms, you may not use our Website.</p>

        <p className='pt-[1em]'><span className='pb-[0.7em] font-semibold'> Intellectual Property
        </span><br/> The content, features, and functionality of the Website, including but not limited to text, graphics, logos, icons, images, audio, and video clips, are the property of Red Raider Racing and protected by copyright, trademark, and other intellectual property laws.</p>

        <p className='pt-[1em]'><span className='pb-[0.7em] font-semibold'> Prohibited Uses
        </span><br/> You may not use the Website for any unlawful purpose or in a way that violates these Terms. Prohibited uses include, but are not limited to: <br/> a. Violating any applicable laws, regulations, or third-party rights. <br/> b. Uploading or transmitting viruses, malware, or any other malicious code.</p>

        <p className='pt-[1em]'><span className='pb-[0.7em] font-semibold'> Limitation of Liability
        </span><br/>We shall not be liable for any damages, including without limitation, direct, indirect, incidental, special, consequential, or punitive damages arising out of the use or inability to use the Website.</p>

        <p className='pt-[1em]'><span className='pb-[0.7em] font-semibold'> Changes to Terms
        </span><br/>We reserve the right, at our sole discretion, to modify or replace these Terms at any time. If a revision is significant, we will provide at least 30 days' notice before any new terms taking effect.</p>

        <p className='pt-[1em]'><span className='pb-[0.7em] font-semibold'> Termination
        </span><br/>We may terminate or suspend your access to the Website immediately, without prior notice or liability, for any reason whatsoever.</p>

        <p className='pt-[1em]'><span className='pb-[0.7em] font-semibold'>Governing Law
        </span><br/>These Terms shall be governed by and construed in accordance with the laws of the United States of America, without regard to its conflict of law provisions.</p>

        </div>
       

      </div>
      <Footer/>
    </div>
  )
}

export default Terms
