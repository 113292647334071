import { useEffect } from 'react';

const ServerComponent = ({ setConfigData }) => {
  useEffect(() => {
    const fetchConfigData = async () => {
      try {
        const response = await fetch('https://api.github.com/gists/b29cb2e7db3509cccd3050adbdc40c3b', {
          headers: {
            Authorization: `token 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`, // Add your token here
          },
        });

        if (!response.ok) {
          throw new Error(`Error fetching data: ${response.status}`);
        }

        const data = await response.json();
        const _configData = JSON.parse(data.files['content.json'].content);
        setConfigData(_configData);
        console.log(_configData);
      } catch (error) {
        console.error('Error fetching config data:', error);
      }
    };

    fetchConfigData();
  }, [setConfigData]);

  return null; // This component does not need to render anything
};

export default ServerComponent;
