import React, { useState } from 'react';
import ServerComponent from './ServerComponent'; // Adjust the path as necessary

const ImageContainerHomepage = () => {
  const [configData, setConfigData] = useState(null);

  return (
    <div className='containerr'>
        <ServerComponent setConfigData={setConfigData} />

        {configData && configData.Contact ? (
          <>
           <h2 className=''><span className='text-primary'>{configData.Home.LatestCarTitle}</span> - Our Latest Car</h2>
        <div className='sm:flex gap-[5vw]'>
            <div className='sm:w-1/2 flexCol'>
              <p className='pb-[2em] sm:pb-0'>{configData.Home.LatestCarText}</p>
            </div>
            <div className='sm:w-1/2'>  
              <img className='w-full h-full object-cover bg-[#D9D9D9]' src={configData.Home.LatestCarImage} alt="sample-pic"></img>
            </div>
        </div>
        </>
      ) : (
        <p>Loading...</p> 
      )}
        
    </div>
  )
}

export default ImageContainerHomepage

// <ServerComponent setConfigData={setConfigData} />
// {configData && configData.Contact ? (
//   <>
   
// </>
// ) : (
// <p>Loading...</p> 
// )}