import React, { useState } from 'react';
import ServerComponent from './ServerComponent'; // Adjust the path as necessary

const Cars = () => {

  const [configData, setConfigData] = useState(null);

  return (
    <div className='containerr carsdata'>
      <h2 className='text-center'>OUR <span className='text-primary'> CARS</span></h2>
      <ServerComponent setConfigData={setConfigData} />
      {configData && (
        <div>
          {/* Render your cars data here */}
          {configData.History.Cars.map((car, index) => (
            <div key={index} className='md:px-[10vw] lg:px-[15vw] mt-[40px]'>
            <img src={car.image} className='bg-[#D9D9D9] w-full aspect-[16/9] object-cover' alt={car.model}></img>
            <div className='flex justify-between'>
              <div className=''>
                <h2 className='pb-0'>{car.model}</h2>
              </div>
              <div className=''>
                <h2 className='pb-0 text-end' >{car.year}</h2>
              </div>
            </div>
            <div className='flex justify-between'>
              <div className=''>
                <p className='text-primary'>CHASIS:</p>
              </div>
              <div className=''>
                <p className='text-[white] text-end'>{car.chasis}</p>
              </div>
            </div>
            <div className='flex justify-between'>
              <div className=''>
                <p className='text-primary'>Power Unit:</p>
              </div>
              <div className=''>
                <p className='text-[white] text-end'>{car.powerunit}</p>
              </div>
            </div>
            <div className='flex justify-between'>
              <div className=''>
                <p className='text-primary'>Weight:</p>
              </div>
              <div className=''>
                <p className='text-[white] text-end'>{car.weight}</p>
              </div>
            </div>
            <div className='flex justify-between'>
              <div className=''>
                <p className='text-primary'>Achievement:</p>
              </div>
              <div className=''>
                <p className='text-[white] text-end'>{car.achievement}</p>
              </div>
            </div>
            <div className='flex justify-between'>
              <div className=''>
                <p className='text-primary'>Competition Placement:</p>
              </div>
              <div className=''>
                <p className='text-[white] text-end'>{car.placement}</p>
              </div>
            </div>
          </div>
          ))}
        </div>
      )}
    </div>

  );
}

export default Cars;