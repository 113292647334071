import React, { useState } from 'react';

const Login = ({ onLogin }) => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();
    // Here you can add your authentication logic
    // For example, check if username and password match expected values
    if (username === "admin" && password === "RedRaiderRacing@2024") { // Example check
      onLogin(); // Call the onLogin function passed as a prop
    } else {
      alert("Invalid credentials");
    }
  };

  return (
    <div className="login-container bg-[white] w-screen h-screen py-[100px]">
      <h2 className='text-black text-center'>Login</h2>
      <form onSubmit={handleSubmit} className='w-[30%] mx-auto text-left'>
        <label>
          Username:<br/>
          <input
            type="text"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            className='mt-[5px] px-[10px] py-[10px] border-[1px] border-[#949494] rounded-[10px] w-full'
            required
          />
        </label>
        <br />
        <label>
          Password:<br/>
          <input
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            className='mt-[5px] px-[10px] py-[10px] border-[1px] border-[#949494] rounded-[10px] w-full'

            required
          />
        </label>
        <br />
        <button type="submit" className='text-[22px] bg-[#0095ff] px-[2em] py-[0.3em] text-[white] rounded-[8px] mt-[20px]'>Login</button>
      </form>
    </div>
  );
};

export default Login;