import React, { useEffect, useState } from 'react';
import ServerComponent from './ServerComponent'; // Adjust the path as necessary


const Faqs = () => {
    // Define the toggleQuestion function outside of useEffect
    const toggleQuestion = (event) => {
      const question = event.currentTarget;
      const active = document.querySelector(".question.active");
      
      if (active && active !== question) {
        active.classList.remove("active");
        active.nextElementSibling.style.maxHeight = 0;
      }
  
      question.classList.toggle("active");
      const answer = question.nextElementSibling;
      if (question.classList.contains("active")) {
        answer.style.maxHeight = answer.scrollHeight + "px";
      } else {
        answer.style.maxHeight = 0;
      }
    };
  useEffect(() => {
    const questions = document.querySelectorAll(".question");

   
    

    questions.forEach(question => {
      question.addEventListener("click", toggleQuestion);
    });

    // Cleanup event listeners on component unmount
    return () => {
      questions.forEach(question => {
        question.removeEventListener("click", toggleQuestion);
      });
    };
  }, []);
  const [configData, setConfigData] = useState(null);

  return (
    
    <div className=''>
        <h2 className='text-center pb-0'><span className='text-primary'>Frequently</span> Asked Questions</h2>
    <div className="containerr ">
    <ServerComponent setConfigData={setConfigData} />

 {configData && configData.Events.WeeklyEvents ? (
  <> {configData.Contact.faqs.map((faq, index) => (


    <div className="contentcontainer" key={index}>
     <div
    className={`question text-left ${faq.active ? 'active' : ''}`}
    onClick={toggleQuestion}
  >{faq.question}</div>
    <div className="answercont">
      <div className="answer text-left">
      {faq.answer}
      </div>
    </div>
  </div>
        ))}</>
) : (
  <p>Loading...</p> 
)}
   


    </div>
    </div>
  );
};

export default Faqs;
