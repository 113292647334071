import './App.css';
import Contact from './pages/Contact';
import Events from './pages/Events';
import Team from './pages/Team';
import Home from './pages/Home';
import Sponsors from './pages/Sponsors';
import History from './pages/History';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import AdminDashboard from './pages/AdminDashboard';
import Terms from './pages/Terms';
import PrivacyPolicy from './pages/PrivacyPolicy';
import Login from './components/Login'; // Import the Login component
import { useState } from 'react'; // Import useState for managing login state

function App() {
  const [isLoggedIn, setIsLoggedIn] = useState(false); // State to manage login status

  const handleLogin = () => {
    setIsLoggedIn(true); // Function to set login status to true
  };

  return (
    <div className="App">
      <Router>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/home" element={<Home />} />

          <Route path="/Team" element={<Team />} />
          <Route path="/Events" element={<Events />} />
          <Route path="/History" element={<History />} />
          <Route path="/Sponsors" element={<Sponsors />} />
          <Route path="/Contact" element={<Contact />} />
          <Route path='/AdminDashboard' element={isLoggedIn ? <AdminDashboard /> : <Login onLogin={handleLogin} />} /> Conditional rendering
          <Route path='/PrivacyPolicy' element={<PrivacyPolicy />} />
          <Route path='/Terms' element={<Terms />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;