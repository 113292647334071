import React, { useState } from 'react';
import ServerComponent from './ServerComponent'; // Adjust the path as necessary


const UpcomingEvents = () => {
  const [configData, setConfigData] = useState(null);

  return (
    
    <div className='containerr'>
        <h2 className='text-center'><span className='text-primary'>Upcoming</span> Events</h2>
        <div className='grid grid-cols-1 sm:grid-cols-3 gap-[3vw]'>
        <ServerComponent setConfigData={setConfigData} />

{configData && configData.Events.UpcomingEvents ? (
  <>{configData.Events.UpcomingEvents.map((event, index) => (
    <div
      key={index}
      className='bg-[white] border-[1px] border-[#AEAEAE] text-[black] rounded-[10px] overflow-hidden'
    >
      <div className='w-full aspect-[16/9] bg-[#EBEBEB] border-b-[1px] border-[#AEAEAE]'>
        <img src={event.image} alt={event.title} className='w-full h-full object-cover'/>
      </div>
      <div className='flex  px-[5%] sm:px-[10%] gap-[0.5em] sm:gap-[1em] py-[0.7em] sm:py-[1em]'>
        <div className='text-center flex flex-col justify-between'>
          <p className='text-[black] text-[14px] sm:text-[14px] lg:text-[16px]'>{event.month}</p>
          <p className=' text-[18px] sm:text-[18px] lg:text-[24px] font-semibold text-primary leading-[1em]'>{event.date}</p>
          
        </div>
        <div className='text-left'>
          <p className='text-[black] text-[14px] sm:text-[16px] lg:text-[18px] font-bold'>{event.title}</p>
          <p className='text-[16px] sm:text-[14px] lg:text-[16px] text-[#A3A3A3] leading-[1.2em] flex'><svg viewBox="0 0 24 24"  className="h-[1.2em]" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" strokeWidth="0"></g><g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g><g id="SVGRepo_iconCarrier"> <path d="M12 21C15.5 17.4 19 14.1764 19 10.2C19 6.22355 15.866 3 12 3C8.13401 3 5 6.22355 5 10.2C5 14.1764 8.5 17.4 12 21Z" stroke="#A3A3A3" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"></path> <path d="M12 12C13.1046 12 14 11.1046 14 10C14 8.89543 13.1046 8 12 8C10.8954 8 10 8.89543 10 10C10 11.1046 10.8954 12 12 12Z" stroke="#A3A3A3" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"></path></g></svg><div className='flex flex-col justify-end'>{event.location}</div></p>        </div>
      </div>
    </div>
  ))}</>
) : (
  <p>Loading...</p> 
)}
          
        </div>
      
    </div>
  )
}

export default UpcomingEvents
