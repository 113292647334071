import React, { useState } from 'react';
import ServerComponent from './ServerComponent'; // Adjust the path as necessary

const HeroContact = () => {
  const [configData, setConfigData] = useState(null);

  return (
    <div>
         <ServerComponent setConfigData={setConfigData} />

{configData && configData.Contact ? (
    <div className='relative'>
      
      <div className='relative w-screen overflow-x-hidden px-[5vw] md:px-[10vw] py-[20vh] sm:py-[25vh]'>
      <div className='picture-overlay w-full absolute h-full inset-0'>

           <img className='absolute object-cover w-full h-full' src={configData.Contact.HeroImage} alt='Background'></img>
     
        <div className='gradient-overlay absolute inset-0' ></div>
      </div>
        <div className='HeroText text-left w-full relative'>
          <h1 className='text-[60px] sm:text-[80px] md:text-[96px] lg:text-[108px] text-[white]  pb-[0.2em] '><span className="text-primary">Ready</span> To Join Us? </h1>


          <div className='sm:flex'><div className='flexCol'><p className='leading-[1em] text-[18px] sm:text-[24px]  md:text-[30px] lg:text-[36px] font-medium'>Join us on </p></div><a href='https://techconnect.dsa.ttu.edu/organization/redraiderracing/' className='ml-[1em]' target="_blank"><img src='/images/Techconnect.png' alt='Techconnect' className='h-[40px] lg:h-[48px]'></img>
          </a></div>
          <div className='sm:flex pt-[1em]'><div className='flexCol'><p className='leading-[1em] text-[18px] sm:text-[24px]  md:text-[30px] lg:text-[36px] font-medium'>Pay your Dues on </p></div><a href='https://www.paypal.com/ncp/payment/L7KHH99ARM9WW' className='ml-[1em]' target="_blank"><img src='/images/PayPal.png' alt='Paypal' className='h-[40px] lg:h-[48px]'></img>
          </a></div>
          <div className='sm:flex pt-[1em]'><div className='flexCol'><p className='leading-[1em] text-[18px] sm:text-[24px]  md:text-[30px] lg:text-[36px] font-medium'>Buy Merch at </p></div><a href='https://www.paypal.com/ncp/payment/AMHKPSZ22QNPQ' className='ml-[1em]' target="_blank"><img src='/images/PayPal.png' alt='Paypal' className='h-[40px] lg:h-[48px]'></img>
          </a></div>
          <div className='sm:flex pt-[1em] '><div className='flexCol'><p className='leading-[1em] text-[18px] sm:text-[24px]  md:text-[30px] lg:text-[36px] pb-[1em] sm:pb-0 font-medium'>Follow us on</p></div>
          <div className='flex gap-[1em]'><a href='https://www.facebook.com/RedRaiderRacing/' className='sm:ml-[1em]' target="_blank">
          <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" className="h-[49px]" viewBox="0 0 58 58">
<path d="M32,6C17.642,6,6,17.642,6,32c0,13.035,9.603,23.799,22.113,25.679V38.89H21.68v-6.834h6.433v-4.548	c0-7.529,3.668-10.833,9.926-10.833c2.996,0,4.583,0.223,5.332,0.323v5.965h-4.268c-2.656,0-3.584,2.52-3.584,5.358v3.735h7.785	l-1.055,6.834h-6.73v18.843C48.209,56.013,58,45.163,58,32C58,17.642,46.359,6,32,6z" fill="white"></path>
</svg>
          </a>

<a href='https://www.instagram.com/redraiderracing/' className='sm:ml-[1em] ' target="_blank">
<svg className="h-[48px]" viewBox="0 0 56 55" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fillRule="evenodd" clipRule="evenodd" d="M27.71 41.9989C35.9942 41.9989 42.71 35.3623 42.71 27.1757C42.71 18.9891 35.9942 12.3525 27.71 12.3525C19.4257 12.3525 12.71 18.9891 12.71 27.1757C12.71 35.3623 19.4257 41.9989 27.71 41.9989ZM27.71 37.0579C33.2327 37.0579 37.71 32.6334 37.71 27.1757C37.71 21.718 33.2327 17.2936 27.71 17.2936C22.1871 17.2936 17.71 21.718 17.71 27.1757C17.71 32.6334 22.1871 37.0579 27.71 37.0579Z" fill="white"/>
<path d="M42.71 9.88208C41.3292 9.88208 40.21 10.9882 40.21 12.3526C40.21 13.717 41.3292 14.8231 42.71 14.8231C44.0907 14.8231 45.21 13.717 45.21 12.3526C45.21 10.9882 44.0907 9.88208 42.71 9.88208Z" fill="white"/>
<path fillRule="evenodd" clipRule="evenodd" d="M1.84486 8.09361C0.209961 11.2645 0.209961 15.4154 0.209961 23.7171V30.6346C0.209961 38.9363 0.209961 43.0873 1.84486 46.258C3.28296 49.0472 5.57766 51.3149 8.40011 52.736C11.6088 54.3517 15.8092 54.3517 24.21 54.3517H31.21C39.6107 54.3517 43.8112 54.3517 47.0197 52.736C49.8422 51.3149 52.137 49.0472 53.575 46.258C55.21 43.0873 55.21 38.9363 55.21 30.6346V23.7171C55.21 15.4154 55.21 11.2645 53.575 8.09361C52.137 5.30443 49.8422 3.03678 47.0197 1.61563C43.8112 0 39.6107 0 31.21 0H24.21C15.8092 0 11.6088 0 8.40011 1.61563C5.57766 3.03678 3.28296 5.30443 1.84486 8.09361ZM31.21 4.94107H24.21C19.9271 4.94107 17.0156 4.94492 14.7652 5.1266C12.5731 5.30359 11.4521 5.62439 10.6701 6.01814C8.78844 6.96559 7.25864 8.47736 6.29989 10.3368C5.90144 11.1096 5.57681 12.2174 5.39771 14.3836C5.21386 16.6075 5.20996 19.4847 5.20996 23.7171V30.6346C5.20996 34.8671 5.21386 37.7441 5.39771 39.968C5.57681 42.1344 5.90144 43.2422 6.29989 44.015C7.25864 45.8743 8.78844 47.3861 10.6701 48.3335C11.4521 48.7273 12.5731 49.0482 14.7652 49.2251C17.0156 49.4067 19.9271 49.4107 24.21 49.4107H31.21C35.493 49.4107 38.4042 49.4067 40.6547 49.2251C42.847 49.0482 43.968 48.7273 44.75 48.3335C46.6315 47.3861 48.1612 45.8743 49.12 44.015C49.5185 43.2422 49.8432 42.1344 50.0222 39.968C50.206 37.7441 50.21 34.8671 50.21 30.6346V23.7171C50.21 19.4847 50.206 16.6075 50.0222 14.3836C49.8432 12.2174 49.5185 11.1096 49.12 10.3368C48.1612 8.47736 46.6315 6.96559 44.75 6.01814C43.968 5.62439 42.847 5.30359 40.6547 5.1266C38.4042 4.94492 35.493 4.94107 31.21 4.94107Z" fill="white"/>
</svg>

          </a></div></div>
        </div>
      </div>
    </div>    
 ) : (
  <p>Loading...</p> 
)}

  </div>
  )
}

export default HeroContact
