



import React, { useState } from 'react';

const ContactForm = () => {
    const [result, setResult] = useState("");
  const [showOverlay, setShowOverlay] = useState(false); // Ensure overlay is hidden by default

  const onSubmit = async (event) => {
    event.preventDefault();
    setResult("Sending....");
    const formData = new FormData(event.target);
    formData.append("access_key", "0e1b1831-2e1d-40a3-aaa2-dfcbb524e430");

    const response = await fetch("https://api.web3forms.com/submit", {
      method: "POST",
      body: formData,
    });

    const data = await response.json();

    if (data.success) {
      setResult("Form Submitted Successfully");
      setShowOverlay(true); // Show overlay on successful submission
      event.target.reset();
    } else {
      console.log("Error", data);
      setResult(data.message);
    }
  };
  return (
    <div className='containerr'>
        <h2 className='text-center'>Still have a <span className='text-primary'>Question</span>?</h2>
        <form onSubmit={onSubmit} method="" action="" className='contactForm text-[16px] sm:text-[18px] md:text-[20px] lg:text-[24px] text-[white] text-left'>

            <div className='flex gap-[2vw]'>
                <div className='sm:w-1/2 py-[0.5em]'>
                    <label htmlFor="fname">First Name</label><br/>
                    <input type='text' id='fname' name='fname' className=' px-[1em] py-[0.4em] w-full' required></input>
                </div>
                <div className='sm:w-1/2 py-[0.5em]'>
                    <label htmlFor="lname">Last Name</label><br/>
                    <input type='text' id='lname' name='lname' className=' px-[1em] py-[0.4em] w-full' required></input>
                </div>
            </div>
            <div className='sm:flex gap-[2vw]'>
        
                <div className='sm:w-1/2 py-[0.5em]'>
                    <label htmlFor="email">Email</label><br/>
                    <input type='email' id='email' name='email' className=' px-[1em] py-[0.4em] w-full' required></input>
                </div>
                <div className='sm:w-1/2 py-[0.5em]'>
                    <label htmlFor="team">Purpose</label><br/>
                    <select name="team" id="team" className='px-[1em] py-[0.4em] w-full'>
                        <option value="Sponsor" className='px-[1em] py-[0.4em]'>Sponsor</option>
                        <option value="Administration" className='px-[1em] py-[0.4em]'>Join Administration</option>
                        <option value="DriveTrain" className='px-[1em] py-[0.4em]'>Join Drivetrain</option>
                        <option value="FBA" className='px-[1em] py-[0.4em]'>Join Frame, Body, and Aero</option>
                        <option value="VehicleDynamics" className='px-[1em] py-[0.4em]'>Join Vehicle Dynamics</option>
                        <option value="Electrical" className='px-[1em] py-[0.4em]'>Join Electrical </option>

                    </select>
                </div>
            </div>
            <div className='text-left full py-[2vw]]'>
                <label htmlFor="name">How can we Help you?</label><br/>
                <textarea id='message' name='message' rows='5' className=' px-[1em] py-[0.4em] w-full box-border' required></textarea>
            </div>

            <div className='flex justify-center sm:justify-end mt-[0.5em]'>
                <button type='submit' className='text-[18px] md:text-[20px]  lg:text-[24px] submitBtn py-[0.3em] px-[3em] text-primary border-[2px] border-primary rounded-[10px]'>Shoot</button>
            </div>
        </form>
      
      {/* Overlay for successful submission */}
      {showOverlay && (
  <div className="overlay fixed top-0 left-0 w-full h-full flex items-center justify-center bg-black bg-opacity-30 z-50 px-[10%] sm:px-0">
  <div className="overlay-content bg-[white] p-6 rounded-md text-center shadow-lg text-[black] border-[3px] border-primary">
    <p className="text-center text-[24px] sm:text-[36px] font-semibold mb-4 text-primary">Message Received!!</p>
    <p className='text-center text-[black]'> We have received your message and you’ll hear from us soon!</p>
    <button
      onClick={() => setShowOverlay(false)}
      className="btn-solid close-btn mt-4 px-4 py-2 bg-[white] text-white rounded-md"
    >
      Close
    </button>
  </div>
</div>
      )}

    </div>
  )
}

export default ContactForm
