import React, { useState } from 'react';
import ServerComponent from './ServerComponent'; // Adjust 

const SponsorsText = () => {
  const [configData, setConfigData] = useState(null);

  const getDownloadLink = (url) => {
    const parts = url.split('/upload/'); // Split the URL at 'upload/'
    if (parts.length === 2) {
      return `${parts[0]}/upload/fl_attachment/${parts[1]}`; // Reassemble with fl_attachment
    }
    return url; // Return the original URL if it doesn't match the expected format
  };
  
  return (
<div className='containerr'>
      
      <ServerComponent setConfigData={setConfigData} />

{configData && configData.Sponsors ? (
  <>
 <h2 className='text-center pb-[1m] pt-[3em]'><span className='text-primary'>why</span> sponsor us?</h2>
 <p className='text-center lg:px-[10vw]'>{configData.Sponsors.WhySponsorText}</p>
        <h2 className='text-center pb-[1m] pt-[3em]'><span className='text-primary'>How to</span> sponsor us?</h2>
        <a className="btn-solid" href={getDownloadLink(configData.Sponsors.SponsorPacket)}  download>Sponsorship Packet</a><br/><br/><br/>
        <a className='btn-solid' href={configData.Sponsors.DonateLink} >GoFundMe</a>

        <p className='text-center lg:px-[10vw] pt-[2em]'>To donate by check, please make checks payable to: Red Raider Racing. </p>
        <p className='text-center lg:px-[10vw] pt-[1em]'>Memo: <br/>For Red Raider Racing</p>
        <p className='text-center lg:px-[10vw] pt-[1em]'>ATTN: <br/>Red Raider Racing</p>
        <p className='text-center lg:px-[10vw] pt-[1em]'>Address: <br/>Texas Tech University<br/>805 Boston Ave, MES #201
        <br/>Lubbock, Texas 79409</p>
        <p className='text-center lg:px-[10vw] pt-[1em]'> For more information, please <a href='/Contact' className='text-primary'>Contact Us</a>.</p>
        </>
) : (
<p>Loading...</p> 
)}

 
    </div>
  )
}

export default SponsorsText
// https://res.cloudinary.com/duclkqnbr/raw/fl_attachmupload/v1732214083/f2cnbojg7yyyiprlokjm.pdf